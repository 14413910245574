import store from '@/store'

export default function useUpcomingPrograms() {

  const fetchProgramList = curPage => {
    const payloadData = {
      perPage: 8,
      page: curPage,
      sortBy: 'position',
    }

    return store.dispatch('app-program/fetchProgramList', payloadData)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchProgramList,
  }
}
