<template>
    <div class="upcoming-programs">
      <div class="static-head">
            <div class="message-container">
                <h1 class="upcoming-programs-title">Register Here</h1>
            </div>
        </div>
        <section class="programs-showcase section">
            <div class="site__centered">
                <h1 class="upcoming-programs__hero"></h1>
                <div class="programs-showcase__list">

                  <ProgramCard
                    v-for="program in programList"
                    :key="program.id"
                    :program="program"
                  />

                </div>

                <div class="programs-showcase__btn-wrap">
                    <button
                      v-if="metaData.current_page !== metaData.last_page"
                      class="btn btn--color-outline-dark"
                      @click="loadMore"
                    ><span>Load more</span>
                    </button>
                </div>

            </div>
        </section>

    </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'

import ProgramCard from '@/views/Program/ProgramCard.vue'

import store from '@/store'
import programStoreModule from '@/views/Program/programStoreModule'
import useUpcomingPrograms from '@/views/Program/useUpcomingPrograms'

export default {
  components: {
    ProgramCard,
  },
  data: () => ({
    programList: [],
    pagesCount: 1,
    metaData: [],
  }),
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, programStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchProgramList,
    } = useUpcomingPrograms()

    return {
      fetchProgramList,
    }
  },
  async mounted() {
   await this.getProgramList()
  },
  methods: {
    async getProgramList() {
      this.$emit('loading', true)
      store.commit('SET_LOADING_STATUS', true)

      this.fetchProgramList(this.pagesCount)
        .then(response => {
          this.metaData = response.meta
          this.programList = this.programList.concat(response.data)
          this.$emit('loading', false)
          store.commit('SET_LOADING_STATUS', false)
        }).finally(() => {
          store.commit('SET_LOADING_STATUS', false)
          this.$emit('loading', false)
        })

    },
    async loadMore() {
      this.pagesCount++
      await this.getProgramList()
    },
  },
}
</script>

<style scoped>
    .message-container {
        position: absolute;
        font-size: 66px;
        font-weight: 700;
        color: white;
        text-align: center;
        width: 100%;
    }
</style>
