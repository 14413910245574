<template>

  <div
    class="programs-item"
  >
    <div>
      <div class="programs-item__pic">
        <img
          :src="program.logo"
          :alt="program.name"
        />
      </div>
      <h3 class="programs-item__topic">{{ program.name }}</h3>
      <div class="programs-item__description" v-html="program.description"></div>
    </div>
    <div>
      <ul class="programs-item__info">
        <li style="align-items: center;">
          <CalendarSVG />
          {{ program.date_start }} <br> {{ program.date_end }} 
        </li>
      </ul>
      <template v-if="role">
        <router-link
          v-if="program.applicationsByProgram"
          :to="{ name: 'application-create', params: { id: program.id },
          query: { app_id: program.applicationsByProgram.id, user_id: program.applicationsByProgram.user_id } }"
          class="btn btn--color-outline-bright"
        >
          <span>{{program.applicationsByProgram.is_accepted ? 'Registered' : 'Continue application' }}</span>
        </router-link>
        <router-link
          v-else
          :disabled="isActiveRegistrationByRole(role, program) ||
            isDisabledRegistrationByGender(role, gender, program)"
          :to="getProgramRegistrationLinkByRole(role, program)"
          class="btn btn--color-outline-bright"
        ><span>Register Here</span>
        </router-link>
      </template>
      <template v-else>
        <div>
          <router-link
                  :disabled ="!program.is_active_for_camper"
                  :to="{name: 'auth-login', params: { return: 'application-create', routeParams: { id: program.id }, role: $config.enable_student_signup ? 'student' : 'parent' } }"
                  class="btn btn--color-outline-bright "
          ><span>{{ camperStudent }}s Registration</span></router-link>
          <router-link
                  :disabled = "!program.is_active_for_staff"
                  :to="{name: 'auth-login', params: { return: 'application-create', routeParams: { id: program.id }, role: 'staff' } }"
                  class="btn btn--color-outline-bright"
          ><span>Staff Registration</span></router-link>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'

import { prepareProgramRegistrationInfo } from '@core/mixins/prepareProgramRegistrationInfo'
import { getUserData } from '@/auth/utils'

import CalendarSVG from '@/assets/images/icons/calendar-2.svg'

export default {
  components: {
    CalendarSVG,
  },
  mixins: [prepareProgramRegistrationInfo, instanceSessionCamperNames],
  data: () => ({
    role: getUserData()?.role,
    gender: getUserData()?.gender,
  }),
  props: {
    program: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    availableCamperRegistration() {
      if (this.$config['enable_student_signup']) {
        return this.program.is_active_for_camper && this.program.applicable_role.includes(this.role)
      }
      return this.program.is_active_for_camper
    }
  },
}
</script>

<style scoped>

</style>
